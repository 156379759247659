<template>
  <div>
    <div class="container">
      <div class="margintop80">
        <h1 class="fontcolor-white">Wie lautet dein <br> Nutzername und dein Kennwort?</h1>
        <form action="/action_page.php" class="margintop80">
          <input type="text" id="nutzernamelogin" name="nutzernamelogin" class="inputclassic backgroundcolor-tertiaryblue fontcolor-white" placeholder="Nutzername" v-model="user">
          <input type="text" id="kennwortlogin" name="kenwortlogin" class="inputclassic backgroundcolor-tertiaryblue fontcolor-white" placeholder="Kennwort" v-model="password">
        </form>
      </div>
    </div>

    <div class="stepnavigation backgroundcolor-secondaryblue">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-secondaryblue col-2 col-center center" @click="loginzuwelcome()">
          <img class="navicon:active" src="../assets/icons/arrow_white.svg" style="transform: scale(-1)">
        </button>
        <button type="button" class="buttonS backgroundcolor-lightblue" @click="loginzuhome()">
          <p class="pbold display-inline fontcolor-white">Einloggen</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg">
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped>
.backgroundlinear {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(#0B1010, #25323A);
}

.inputclassic {
  background-color: #25323A;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
  color: white;
}

.inputclassic:focus {
  outline: none;
}

::placeholder {
  color: #7C8489;
  opacity: 1;
}

</style>

<script>
  import { mapActions } from 'vuex';
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";


  export default {
    name: 'information',
    components: {
      'foot-navigation' : FootNavigationVue,
    }, 
    data() {
      return {
        user: '',
        password: '',
      }
    },
    mounted() {
      if (localStorage.user) {
        this.user = localStorage.user;
      }
    },
    methods: {
      ...mapActions(['loadUserData']),
      loginzuhome() {
        localStorage.user = this.user;
        this.loadUserData({
            user: this.user,
            pw: this.password
        });
        setTimeout(()=> router.push({ path: '/home' }), 1000); // -> /user/eduardo
      },
      loginzuwelcome() {
        router.push({path: '/'})
      }
    }
  };
</script>
